<template>
  <div class="my-modal-overlay"
    :class="[show ? 'opened-dialog' : 'closed-dialog']">
    <div class="dialog-container">
      <ActionDetailsCard @edit-action="$emit('edit-action')"/>

      <div class="close-btn">
        <v-btn
          icon
          depressed
          color="#262338"
          width="24"
          height="24"
          @click="$emit('close')"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
  import ActionDetailsCard from '@/components/HiveManagement/ActionDetailsCard.vue';

  export default {
    name: 'ActionDetailsModal',
    components: {
      ActionDetailsCard
    },
    props: {
     visible: Boolean
    },
    computed: {
      show: {
        get () {
          return this.visible
        },
        set (value) {
           if (!value) {
            this.$emit('close');
          }
        }
      }
    }
  }
</script>

<style type="text/css">
  #edit-action-card {
    position: relative;
  }
</style>
