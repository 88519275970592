<template>
  <v-list id="more-menu">
    <v-list-item
      link
      @click="viewCompleted"
    >
      <v-list-item-content>
        <v-list-item-title>
          {{$t(completedTitle)}}
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-list-item
      v-if="!hidePrint"
      link
      @click="$emit('print-page')"
      :disabled="disablePrint"
    >
      <v-list-item-content>
        <v-list-item-title>
          {{$t('printExport')}}
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>

  </v-list>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex';

  export default {
    name: 'MoreMenu',
    props: {
      hidePrint: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      ...mapGetters(["selectedHives", "completedTitle"]),
      isSelectedHivesEmpty() {
        return this.selectedHives.length === 0;
      },
      disablePrint() {
        return this.completedTitle === 'viewUncompleted';
      }
    },
    methods: {
      ...mapActions(["setCompletedTitle"]),
      viewCompleted() {
        if (this.completedTitle == 'viewUncompleted') {
          this.$emit('view-uncompleted');
          this.setCompletedTitle('viewCompleted');
        } else {
          this.$emit('view-completed');
          this.setCompletedTitle('viewUncompleted');
        }
      }
    }
  };
</script>

<style type="text/css">
  #more-menu {
    padding: 20px;
  }

  #more-menu .v-list-item__title {
    font-size: 13px;
    font-weight: 600;
    color: #262338;
    line-height: 22px;
    letter-spacing: 0.25px;
  }
</style>