<template>
  <div class="dialog-overlay" 
    :class="[show ? 'opened-dialog' : 'closed-dialog']">
    <div class="dialog-container-sm">
      <v-card flat id="subscription-card" class="my-card">
        <div class="close-btn">
          <v-btn
            icon
            depressed
            color="#262338"
            width="24"
            height="24"
            @click="$emit('close')"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>

        <v-row class="mt-2">
          <v-col cols="12">
            <h4 class="email-verification">{{ $t("freeTrialExpired1") }} {{ $t("freeTrialExpired2") }}</h4>
            <!-- <h4 class="email-verification"></h4> -->
          </v-col>
        </v-row>
        
        <v-row class="mt-6">
          <v-col cols="6" class="text-center">
            <v-btn
              color="#eaac30"
              class="plan-button"
              height="72"
              depressed
              block
              @click="goToSubscriptionPage('month')"
            >
              <p class="text-wrap" style="margin:auto;">
                {{$t('billMonthly1')}} <br> {{$t('billMonthly2')}}
              </p>
            </v-btn>
          </v-col>
          <v-col cols="6" class="text-center">
            <v-btn
              color="#eaac30"
              class="plan-button"
              height="72"
              depressed
              block
              @click="goToSubscriptionPage('year')"
            >
              <p class="text-wrap" style="margin:auto;">
                {{$t('billYearly1')}} <br> {{$t('billYearly2')}}
              </p>
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </div>
  </div>
</template>

<script>
  import {mapGetters, mapActions} from 'vuex';

  export default {
    name: 'SubscriptionModal',
    props: {
     visible: Boolean,
     actionType: String
    },
    computed: {
      ...mapGetters(["selectedHives"]),
      show: {
        get () {
          return this.visible
        },
        set (value) {
           if (!value) {
            this.$emit('close');
          }
        }
      }
    },
    data() {
      return {};
    },
    methods: {
      goToSubscriptionPage(plan) {
        this.$emit('go-to-subscription-page', plan);
      }
    }
  }
</script>

<style type="text/css">
  .email-verification {
    text-align: center;
    color: #4E4B66;
    margin-bottom: 10px;
  }

  #subscription-card .plan-button {
    text-align: center;
    text-transform: none;
    background: #eaac30;
    color: #262338;
    padding: 12px 24px;
    border-radius: 12px;
    font-weight: 600;
  }
</style>