<template>
  <v-card flat class="my-card" id="management-tasks-details" :min-height="minHeight">
    <v-row>
      <v-col cols="10">
        <div class="task-details-title">
          {{actionDetails.title}}
        </div>
        <div class="task-details-date d-flex">
          <span class="tasks-calendar-icon">
            <v-img
              contain
              height="24"
              width="24"
              :src="`${publicPath}img/icons/`+actionDetails.icon+'.svg'"
            ></v-img>
          </span>
          <span>{{getTaskDetailsDate(actionDetails.initiated_at)}} </span>
          <span class="pl-1">({{actionDetails.initiated_at}})</span>
        </div>
      </v-col>
      <v-col
        v-if="showSmallEditBtn"
        cols="2"
        class="d-flex justify-end align-center"
      >
        <v-btn fab small depressed color="#EAAC30"
          @click="$emit('edit-action')">
          <v-icon size="16">{{edit_icon}}</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-row class="mt-4">
      <v-col cols="12">
        <v-textarea
          v-model="notes"
          label="Your Comments"
          readonly
          filled
          auto-grow
          hide-details
          no-resize
          rows="5"
          :prepend-inner-icon="file_icon"
          color="#262338"
          background-color="#EFF0F7"
          class="my-textarea"
        ></v-textarea>
      </v-col>
    </v-row>
    <div class="mt-10 d-flex">
      <span>
        <v-img
          contain
          height="24"
          width="24"
          src="@/assets/box.svg"
        ></v-img>
      </span>
      <span class="hives-title">{{$t('hives')}}</span>
    </div>
    <v-row class="mt-4 hive-numbers-container">
      <v-chip
        v-for="item in actionDetails.hive_numbers"
        class="ma-2 hive-chip"
        color="#EFF0F6"
        text-color="#4E4B66"
      >
        {{item.number}}
      </v-chip>
    </v-row>
    <v-row
      v-if="!showSmallEditBtn && !actionDetails.completed"
      class="edit-hives-btn-container"
    >
      <v-col cols="12" sm="12" md="8" lg="6" xl="5">
        <v-btn block outlined class="my-btn edit-hives-btn"
          @click="$emit('edit-action')">
          {{$t('editAction')}}
          <v-spacer></v-spacer>
          <v-icon size=16>{{edit_icon}}</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
  import {mapGetters} from 'vuex';
  import moment from "moment";

  export default {
    name: 'ActionDetailsCard',
    props: {
      showSmallEditBtn: {
        type: Boolean,
        default: false
      },
      minHeight: {
        type: Number,
        default: 580
      }
    },
    computed: {
      ...mapGetters(["actionDetails"]),
      lang: {
        get() {
          return this.$i18n.locale;
        }
      },
      notes: {
        get () {
          let result = '';
          if (Object.keys(this.$store.state.actions.actionDetails).length != 0) {
            result = this.$store.state.actions.actionDetails.notes;
          }
          return result;
        },
        set(value) {
          return;
        }
      }
    },
    data: () => ({
      publicPath: process.env.BASE_URL,
      file_icon: "$vuetify.icons.file_icon",
      edit_icon: "$vuetify.icons.edit_icon",
    }),
    methods: {
      getTaskDetailsDate(date) {
        return moment(date).locale(this.lang).format("ddd D MMMM YYYY");
      }
    }
  };
</script>

<style type="text/css">
  .task-details-title {
    font-weight: 600;
    font-size: 20px;
    line-height: 32px;
    letter-spacing: 1px;
    color: #4E4B66;
  }

  .task-details-date {
    font-weight: 600;
    font-size: 13px;
    line-height: 22px;
    letter-spacing: 0.25px;
    color: #6E7191;
  }
  
  #management-tasks-details .v-textarea.v-text-field--enclosed textarea {
    margin-top: 18px;
    font-weight: 500;
    font-size: 13px;
    line-height: 22px;
    letter-spacing: 0.25px;
    color: #14142B;
  }

  #management-tasks-details .my-textarea label.v-label {
    top: 0px;
    font-weight: 600;
    font-size: 15px;
    line-height: 22px;
    letter-spacing: 0.25px;
    color: #6E7191 !important;
  }

  #management-tasks-details .my-textarea label.v-label--active {
    transform: translateY(0px) scale(0.85);
  }

  #management-tasks-details .my-textarea.v-text-field--enclosed .v-input__prepend-inner {
    margin-top: 0;
  }

  #management-tasks-details .hives-title {
    font-weight: 600;
    font-size: 15px;
    line-height: 24px;
    letter-spacing: 0.75px;
    padding-left: 10px;
  }

  #management-tasks-details .hive-chip {
    width: 60px;
    height: 28px;
    padding: 3px 10px;
    font-size: 13px;
    line-height: 22px;
    font-weight: 500;
    letter-spacing: 0.25px;
    justify-content: center;
    border-radius: 8px;
  }

  #management-tasks-details .edit-hives-btn-container {
    margin-top: 20px;
    justify-content: flex-end;
  }

  #management-tasks-details button.my-btn.edit-hives-btn {
    font-size: 15px;
    line-height: 24px;
    letter-spacing: 0.75px;
    background-color: #EAAC30;
    border: 2px solid #EAAC30;
    padding: 9px 22px;
    height: 50px;
  }

  #management-tasks-details button.my-btn.edit-hives-btn:hover {
    background-color: #ffffff;
    color: #262338;
  }

  #management-tasks-details button.edit-hives-btn:hover::before {
    opacity: 0;
  }

  .hive-numbers-container {
    height: 100px;
    overflow-y: auto;
  }

  @media screen and (max-width: 960px) {
    #management-tasks-details.my-card {
      border-radius: 0;
    }
  }
</style>