<template>
  <div class="tab-content" id="edit-task-tab">
    <v-row>
      <v-col cols="12" md="8" lg="7">
        <label class="my-label">{{$t('title')}}</label>
        <v-text-field
          v-model="title"
          solo
          flat
          hide-details
          color="#262338"
          background-color="#EFF0F7"
          class="my-input"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="4" lg="5">
        <label class="my-label">{{$t("destination_apiary")}}</label>
        <v-select
            :items="apiariesList"
            v-model="destinationApiary"
            item-text="name"
            item-value="id"
            :label="$t('select_apiary')"
            :placeholder="$t('select_apiary')"
            solo
            flat
            hide-details
            color="#14142B"
            background-color="#EFF0F6"
            class="my-select-box"
            append-icon="mdi-chevron-down"
            :menu-props="{ bottom: true, offsetY: true }"
          >
            <template slot="item" slot-scope="data">
              <span class="my-list-item">{{data.item.name}}</span>
            </template>
            <template slot="selection" slot-scope="data">
              <span class="my-list-item">{{data.item.name}}</span>
            </template>
        </v-select>
      </v-col>
    </v-row>
    <v-row class="mt-8">
      <v-col cols="12" md="8" lg="7">
        <v-textarea
          v-model="notes"
          :label="$t('add_details')"
          filled
          color="#262338"
          background-color="#EFF0F7"
          auto-grow
          class="my-textarea"
        ></v-textarea>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import {mapGetters} from 'vuex';
  import filters from '@/assets/filters.json';

  export default {
    name: 'ActionsTaskTab',
    computed: {
      ...mapGetters(["inventory", "selectedApiary"]),
      title: {
        get () {
          let result = '';
          if (Object.keys(this.$store.state.actions.editActionItem).length != 0) {
            result = this.$store.state.actions.editActionItem.title;
          }
          return result;
        },
        set(value) {
          this.$store.commit('setEditActionItemProp', {prop: 'title', value: value});
        }
      },
      notes: {
        get () {
          let result = '';
          if (Object.keys(this.$store.state.actions.editActionItem).length != 0) {
            result = this.$store.state.actions.editActionItem.notes;
          }
          return result;
        },
        set(value) {
          this.$store.commit('setEditActionItemProp', {prop: 'notes', value: value});
        }
      },
      destinationApiary: {
        get () {
          return this.$store.state.actions.editActionItem.destination_apiary;
        },
        set(value) {
          this.$store.commit('setEditActionItemProp', {prop: 'destination_apiary', value: value});
        }
      },
      apiariesList() {
        let result = this.inventory.filter((item) => {
          return item.id != this.selectedApiary.id;
        });
        return result;
      }
    },
    data: () => ({

    })
  }
</script>


<style type="text/css">
  #edit-task-tab .my-input {
    border-radius: 8px;
    font-weight: 600;
    font-size: 13px;
    line-height: 22px;
    letter-spacing: 0.25px;
    color: #14142B;
    margin-top: 16px;
  }

  #edit-task-tab .my-input .v-input__slot {
    padding: 9px 22px;
  }

  #edit-task-tab .my-select-box {
    border-radius: 8px;
    font-weight: 600;
    font-size: 13px;
    line-height: 22px;
    letter-spacing: 0.25px;
    color: #14142B;
    padding-top: 16px;
  }
</style>